import { useRouter } from 'next/router';

const useContact = () => {
  const router = useRouter();

  function show() {
    router.push('/contact');
  }

  function showSupport() {
    router.push('/contact/support');
  }

  function hide() {
    router.back();
  }

  return { show, showSupport, hide };
};

export default useContact;
