export const PRODUCT_LISTS = [
  {
    heading: 'Explore',
    items: [
      {
        type: 'link',
        label: 'SQL editor',
        href: '/product/sql-editor',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/sql-editor.svg`,
      },
      {
        type: 'link',
        label: 'Data catalog',
        href: '/product/data-catalog',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/data-catalog.svg`,
      },
      {
        type: 'link',
        label: 'Query variables',
        href: '/product/query-variables',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/query-variables.svg`,
      },
    ],
  },
  {
    heading: 'Collaborate',
    items: [
      {
        type: 'link',
        label: 'Shared queries',
        href: '/product/shared-queries',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/shared-queries.svg`,
      },
      {
        type: 'link',
        label: 'Search',
        href: '/product/search',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/search.svg`,
      },
      {
        type: 'link',
        label: 'Version history',
        href: '/product/version-history',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/version-history.svg`,
      },
    ],
  },
  {
    heading: 'Visualize',
    items: [
      {
        type: 'link',
        label: 'Charts',
        href: '/product/charts',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/charts.svg`,
      },
      {
        type: 'link',
        label: 'Dashboards',
        href: '/product/dashboards',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/dashboards.svg`,
      },
      {
        type: 'link',
        label: 'Notebooks',
        href: '/notebooks',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/notebooks.svg`,
      },
    ],
  },
  {
    heading: 'Connect',
    items: [
      {
        type: 'link',
        label: 'Connections',
        href: '/connections',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/connections.svg`,
      },
      {
        type: 'link',
        label: 'Schedules',
        href: '/product/scheduled-queries',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/schedules.svg`,
      },
      {
        type: 'link',
        label: 'dbt Core',
        href: '/dbt',
        icon: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/feature-icons/dbt.svg`,
      },
    ],
  },
];
