import { useRouter } from 'next/router';
import React, { useCallback, useMemo, useState } from 'react';
import { FiX } from 'react-icons/fi';

import PROMOTED from '../../promoted';
import { gaEvent } from '../../utils/ga';
import { sendToDataLayer } from '../../utils/track';

const FLASH_TYPES = ['success', 'error', 'notice', 'alert'];
const FLASH_STYLES = {
  success: { background: '#3182EB', color: '#FFFFFF' },
  error: { background: '#E43D55', color: '#FFFFFF' },
  alert: { background: '#FFB800', color: '#000000' },
  notice: { background: '#3182EB', color: '#FFFFFF' },
};

export const generateFlashString = (type, text) => {
  return btoa(JSON.stringify({ [type]: text }));
};

const getPayloadAndAnalytics = (flash) => {
  if (!flash) return null;
  let payload;
  try {
    payload = JSON.parse(atob(flash));
  } catch (e) {
    return null; // don't render, no flash
  }
  const payloadKeys = Object.keys(payload);
  if (payloadKeys.includes('mixpanel_reset')) {
    if (analytics) {
      analytics.reset();
    }
  } else if (payloadKeys.includes('user_id_that_just_signed_up')) {
    if (analytics) {
      analytics.alias(payload.user_id_that_just_signed_up);
    }
    gaEvent('Auth', 'Sign Up');

    sendToDataLayer({
      event: 'Sign Up',
      userEmailType: payload.user_email_type,
    });

    if (payloadKeys.includes('data_layer_event')) {
      sendToDataLayer(JSON.parse(payload.data_layer_event));
    }
  } else if (payloadKeys.includes('just_started_subscription')) {
    gaEvent('Subscriptions', 'Start');
  }
  return payload;
};

const AnnouncementFlash = () => {
  const router = useRouter();
  // const [isDismissed, setIsDismissed] = useState(false);
  // const handleDismiss = useCallback(() => setIsDismissed(true), []);

  if (PROMOTED.length === 0) return null;

  const promo = PROMOTED[Math.floor(Math.random() * PROMOTED.length)];

  if (router.pathname === '/404') {
    return null; // something causes the announcement to be duplicate in the 404 route, so for now just dont show it.
  }

  if (promo.inIndex ? false : router.pathname === '/') {
    return null; // dont render this on index if promo specifies it
  }

  // if (isDismissed) return null;

  return (
    <a
      href={promo.url}
      style={{ backgroundColor: `#00B2FF` }}
      className={`text-white space-x-3 font-semibold justify-center flex items-center z-50 w-full py-3 text-center text-xs md:text-sm font-medium leading-10 font-inter relative`}
    >
      {promo.flashBadge}

      <span>
        {promo.intro && `${promo.intro} `}
        {`${promo.title}`}
      </span>

      <span className="text-white border-b border-white border-opacity-40 ml-1 hidden md:block">
        Read more →
      </span>
    </a>
  );
};

const Flash = () => {
  const router = useRouter();
  const { flash } = router.query;
  const [isDismissed, setIsDismissed] = useState(false);
  const handleDismiss = useCallback(() => setIsDismissed(true), []);

  // IMPORTANT, if we don't memoize the flash we might get duplicate results
  const payload = useMemo(() => getPayloadAndAnalytics(flash), [flash]);
  if (!payload) return <AnnouncementFlash />; // if no payload, render announcement flash
  const payloadKeys = Object.keys(payload);
  if (!payloadKeys.length) {
    return null; // don't render, no flash
  }
  let type;
  // find the type
  for (let i = 0; i < payloadKeys.length; i++) {
    if (FLASH_TYPES.includes(payloadKeys[i])) {
      type = payloadKeys[i];
      break;
    }
  }
  if (!type) return null;
  const message = payload[type] || '';
  if (!message) {
    return null;
  }
  const style = FLASH_STYLES[type] || {};

  if (isDismissed) return null;
  return (
    <div
      className={`w-full h-10 text-center text-sm font-medium leading-10 font-inter relative`}
      style={style}
    >
      {message}
      <button
        onClick={handleDismiss}
        className="absolute"
        style={{ right: '24px', top: '13px' }}
      >
        <FiX />
      </button>
    </div>
  );
};

export default Flash;
