import ROUTES from '../routes';

export const RESOURCES_LIST = [
  {
    type: 'link',
    label: 'Changelog',
    href: ROUTES.changelog,
  },
  {
    type: 'link',
    label: 'Blog',
    href: ROUTES.blog,
  },
  {
    type: 'link',
    label: 'Docs',
    href: ROUTES.docs,
  },
  {
    type: 'link',
    label: 'Customers',
    href: ROUTES.customers,
  },
  {
    type: 'link',
    label: 'Tutorials',
    href: ROUTES.learn,
  },
  {
    type: 'link',
    label: 'Templates',
    href: ROUTES.sqlTemplates,
  },
  {
    type: 'link',
    label: `Buyers' guide`,
    href: ROUTES.sqlEditorBuyersGuide,
  },
];

export const FOOTER_RESOURCES_LIST = [
  {
    type: 'link',
    label: 'Changelog',
    href: ROUTES.changelog,
  },
  {
    type: 'link',
    label: 'Blog',
    href: ROUTES.blog,
  },
  {
    type: 'link',
    label: 'Docs',
    href: ROUTES.docs,
  },
  {
    type: 'link',
    label: 'Customers',
    href: ROUTES.customers,
  },
  {
    type: 'link',
    label: 'SQL tutorials',
    href: ROUTES.learn,
  },
  {
    type: 'link',
    label: 'dbt tutorials',
    href: ROUTES.learnDbt,
  },
  {
    type: 'link',
    label: 'Templates',
    href: ROUTES.sqlTemplates,
  },
  {
    type: 'link',
    label: `Buyers' guide`,
    href: ROUTES.sqlEditorBuyersGuide,
  },
];

export const DOWNLOADS_LIST = [
  {
    type: 'link',
    label: 'Mac (Intel)',
    href: ROUTES.getPopSQL('dmg'),
  },
  {
    type: 'link',
    label: 'Mac (M1/M2)',
    href: ROUTES.getPopSQL('dmg_arm64'),
  },
  {
    type: 'link',
    label: 'Windows',
    href: ROUTES.getPopSQL('exe'),
  },
  {
    type: 'link',
    label: 'Linux',
    href: ROUTES.getPopSQL('AppImage'),
  },
  {
    type: 'separator',
  },
  {
    type: 'link',
    label: 'PopSQL for web',
    href: ROUTES.appPopSQL,
  },
];

export const COMPANY_LINKS = [
  {
    type: 'link',
    label: 'About us',
    href: ROUTES.about,
  },
  {
    type: 'link',
    label: 'Security',
    href: ROUTES.security,
  },
  {
    type: 'link',
    label: 'Terms of service',
    href: ROUTES.terms,
  },
  {
    type: 'link',
    label: 'Privacy policy',
    href: ROUTES.privacy,
  },
  {
    type: 'link',
    label: 'Data Processing Addendum',
    href: ROUTES.dpa,
  },
  {
    type: 'link',
    label: 'Cookies',
    onClick: () => Osano.cm.showDrawer('osano-cm-dom-info-dialog-open'),
  },
  {
    type: 'link',
    label: 'Status',
    href: ROUTES.status,
  },
];
