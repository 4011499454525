// import React from 'react';

// {
//   flashClasses: 'bg-cobalt-100 dark:bg-white text-white dark:text-black',
//   flashBadge: (
//     <img
//       style={{ width: `16px`, height: `16px` }}
//       src={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/features/dbt/dbtbadge.png`}
//     />
//   ),
//   image: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/features/dbt/og.jpg`,
//   intro: 'Introducing PopSQL + dbt',
//   title: 'The first SQL editor with built-in dbt support',
//   url: '/dbt/',
// },

const PROMOTED = [];

export default PROMOTED;
