const ROUTES = {
  changelog: '/changelog',
  blog: '/blog',
  docs: '/docs',
  about: '/about',
  dpa: '/dpa',
  security: '/security',
  learn: '/learn-sql',
  learnDbt: '/learn-dbt',
  customers: '/customers',
  sqlTemplates: '/sql-templates',
  pricing: '/pricing',
  product: '/product',
  privacy: '/privacy',
  terms: '/terms',
  connections: '/connections',
  demo: '/demo',
  demoBookDirectly: 'https://meetings.hubspot.com/paiman/popsql-overview',
  status: 'https://popsqlstatus.com/',
  docsPost: (id, slug) => {
    if (id && !slug) {
      return {
        href: '/docs/[id]',
        asPath: `/docs/${id}`,
      };
    }
    return {
      href: '/docs/[id]/[slug]',
      asPath: `/docs/${id}/${slug}`,
    };
  },
  learnPost: (id, slug) => {
    if (id && !slug) {
      return {
        href: '/learn-sql/[id]',
        asPath: `/learn-sql/${id}`,
      };
    }
    return {
      href: '/learn-sql/[id]/[slug]',
      asPath: `/learn-sql/${id}/${slug}`,
    };
  },
  sqlTemplatesPost: (id, slug) => {
    if (id && !slug) {
      return {
        href: '/sql-templates/[id]',
        asPath: `/sql-templates/${id}`,
      };
    }
    return {
      href: '/sql-templates/[id]/[slug]',
      asPath: `/sql-templates/${id}/${slug}`,
    };
  },
  changelogPost: (slug) => ({
    href: '/changelog/[slug]',
    asPath: `/changelog/${slug}`,
  }),
  blogPost: (slug) => ({
    href: '/blog/[slug]',
    asPath: `/blog/${slug}`,
  }),
  sqlEditorBuyersGuidePost: (slug) => ({
    href: '/sql-editor-buyers-guide/[slug]',
    asPath: `/sql-editor-buyers-guide/${slug}`,
  }),
  connectionGuide: (connection) => ({
    href: `/connections/${connection}`,
    asPath: `/connections/${connection}`,
  }),
  account: '/account',
  signIn: '/users/sign_in',
  signUp: '/users/sign_up',
  getPopSQL: (platform) => `https://get.popsql.com/download/${platform}`,
  appPopSQL: 'https://app.popsql.com',
  download: '/download',
  gitHubStudents: '/github-students',
  sqlEditorBuyersGuide: '/sql-editor-buyers-guide',
  contact: '/contact',
  openRoles: '13',
};

export default ROUTES;
