import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useContext, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import SVG from 'react-inlinesvg';

import { Context } from '../../contexts/AppContext';
import { PRODUCT_LISTS } from '../../data/products';
import { RESOURCES_LIST } from '../../data/resources';
import ROUTES from '../../routes';
import { gaEvent } from '../../utils/ga';
import Button from '../Button';
import Flash from '../Flash';
import Logo from '../Logo';
import ProductIcon from '../ProductIcon';

import useContact from '@hooks/useContact';

const BetaBadge = () => (
  <span className="bg-gray-400 dark:bg-cobalt-200 px-[5px] py-[3px] tracking-[0.5px] rounded-full text-white font-bold uppercase text-[9px]">
    Beta
  </span>
);

export const HeaderCTA = ({ isLoggedIn }: { isLoggedIn?: boolean }) => {
  const download = useCallback(() => {
    gaEvent('Download', 'Top Menu');
  }, []);
  const getStarted = useCallback(() => {
    gaEvent('Get Started', 'Top Menu');
  }, []);

  return (
    <>
      {isLoggedIn ? (
        <Button href={ROUTES.appPopSQL} onClick={download} hasArrow size="sm">
          Open app
        </Button>
      ) : (
        <Button
          href={`${process.env.NEXT_PUBLIC_WEB_HOST}${ROUTES.signUp}`}
          onClick={getStarted}
          hasArrow
          size="sm"
        >
          Get started
        </Button>
      )}
    </>
  );
};

const ListItem = React.forwardRef<
  HTMLAnchorElement,
  {
    children?: JSX.Element | string | JSX.Element[];
    className?: string;
    href?: string;
    icon?: string;
    isBeta?: boolean;
    ref: any;
    title: string;
  }
>(({ className, children, title, icon, isBeta, ...props }, forwardedRef) => (
  <li>
    <NavigationMenu.Link asChild>
      <a
        className={cn(
          'focus:text-pink group block select-none rounded-[6px] px-4 py-[4px] text-[14px] leading-none no-underline outline-none focus:outline-none focus:outline-none focus:ring-none transition-colors',
          className,
        )}
        {...props}
        ref={forwardedRef}
      >
        <div
          className={`${
            props.href ? 'text-black' : 'text-gray-300'
          } items-center text-[13px] mb-[5px] font-semibold leading-[1.2] flex`}
        >
          {icon && (
            <div className={cn('shrink-0')}>
              <ProductIcon>
                <SVG height={12} width={12} src={icon} />
              </ProductIcon>
            </div>
          )}
          <div
            className={cn(
              `shrink-0 flex items-center gap-[6px] ${
                props.href ? 'dark:text-white' : 'dark:text-cobalt-300'
              }`,
            )}
          >
            {title} {isBeta && <BetaBadge />}
          </div>
          {props.href && (
            <div className="transition shrink-0 ease-in-out invisible group-hover:visible group-focus:visible group-hover:translate-x-[4px] ml-[1px] dark:text-white">{`->`}</div>
          )}
        </div>
        {children && (
          <p className="leading-[1.4] mb-1 text-xs font-medium">{children}</p>
        )}
      </a>
    </NavigationMenu.Link>
  </li>
));

const LinkGroup = ({
  className,
  children,
  title,
}: {
  children: JSX.Element[] | JSX.Element;
  className?: string;
  title?: string;
}) => (
  <li className={cn('flex flex-col pt-[10px] sm:pb-2', className)}>
    {title && (
      <div className="text-gray-400 dark:text-bliss text-xs font-semibold mx-4 py-[6px]">
        {title}
      </div>
    )}
    <ul>{children}</ul>
  </li>
);

const ProductMenu = () => (
  <NavigationMenu.Content className="absolute top-0 left-0 w-full sm:w-auto">
    <ul className="dark:bg-cobalt-100 grid list-none pb-3 sm:pb-1 sm:w-[520px] sm:grid-cols-2 gap-0">
      <LinkGroup
        className="sm:border-b-[0.5px] dark:border-gray-700"
        title={PRODUCT_LISTS[0].heading}
      >
        {PRODUCT_LISTS[0].items.map((item, i) => (
          <ListItem
            key={i}
            href={item.href}
            title={item.label}
            icon={item.icon}
            isBeta={item.isBeta}
          />
        ))}
      </LinkGroup>
      <LinkGroup
        className="sm:border-l-[0.5px] dark:border-gray-700 sm:border-b-[0.5px]"
        title={PRODUCT_LISTS[1].heading}
      >
        {PRODUCT_LISTS[1].items.map((item, i) => (
          <ListItem
            key={i}
            href={item.href}
            title={item.label}
            icon={item.icon}
            isBeta={item.isBeta}
          />
        ))}
      </LinkGroup>
      <LinkGroup title={PRODUCT_LISTS[2].heading}>
        {PRODUCT_LISTS[2].items.map((item, i) => (
          <ListItem
            key={i}
            href={item.href}
            title={item.label}
            icon={item.icon}
            isBeta={item.isBeta}
          />
        ))}
      </LinkGroup>
      <LinkGroup
        className="sm:border-l-[0.5px] dark:border-gray-700"
        title={PRODUCT_LISTS[3].heading}
      >
        {PRODUCT_LISTS[3].items.map((item, i) => (
          <ListItem
            key={i}
            href={item.href}
            title={item.label}
            icon={item.icon}
            isBeta={item.isBeta}
          />
        ))}
      </LinkGroup>
      {/* <LinkGroup className="border-t-[0.5px] dark:border-gray-700 mt-3 sm:mt-0 sm:col-span-2">
        <ListItem href="/" title="View all features" />
      </LinkGroup> */}
    </ul>
  </NavigationMenu.Content>
);

const MenuTopItem = ({
  title,
  children,
  href,
  icon,
  onClickFn,
}: {
  children?: JSX.Element | JSX.Element[];
  href?: string;
  icon?: JSX.Element | string;
  onClickFn?: () => void;
  title: string;
}) => {
  const menuLinkClasses = cn(
    'text-black group mx-[0.5px] flex select-none items-center justify-between gap-[2px] rounded-[4px] px-3 py-2 text-[14px] font-medium leading-none outline-none focus:outline-none rounded-full',
    'bg-white dark:bg-cobalt-100 dark:text-blue-50 dark:hover:bg-cobalt-50',
    'data-[state=open]:bg-gray-100 hover:bg-gray-100',
  );

  const iconClasses = 'ml-[6px]';
  return (
    <NavigationMenu.Item>
      {children && (
        <NavigationMenu.Trigger
          onClick={(e) => e.preventDefault()}
          className={menuLinkClasses}
        >
          {title}
          <FiChevronDown
            size={12}
            className="opacity-50 ml-[2px] transition-transform duration-[250] ease-in group-data-[state=open]:-rotate-180"
          />
        </NavigationMenu.Trigger>
      )}

      {href && (
        <NavigationMenu.Link className={menuLinkClasses} href={href}>
          {title}
          {icon && <span className={iconClasses}>{icon}</span>}
        </NavigationMenu.Link>
      )}

      {onClickFn && (
        <NavigationMenu.Trigger className={menuLinkClasses} onClick={onClickFn}>
          {title}
          {icon && <span className={iconClasses}>{icon}</span>}
        </NavigationMenu.Trigger>
      )}

      {children}
    </NavigationMenu.Item>
  );
};

const ResourcesMenu = () => (
  <NavigationMenu.Content className="absolute top-0 left-0 w-full sm:w-auto">
    <ul className="dark:bg-cobalt-100 m-0 grid list-none pt-4 pb-3 sm:w-[430px] gap-2 grid-cols-2">
      {RESOURCES_LIST.map((item, i) => {
        return <ListItem key={i} title={item.label} href={item.href} />;
      })}
    </ul>
  </NavigationMenu.Content>
);

// const DownloadMenu = () => (
//   <NavigationMenu.Content className="absolute top-0 left-0 w-full sm:w-auto">
//     <ul className="dark:bg-cobalt-100 m-0 grid list-none pt-3 pb-2 sm:w-[250px] gap-2">
//       {DOWNLOADS_LIST.map((item, i) => {
//         if (item.type === 'separator')
//           return (
//             <div
//               key={i}
//               className="border-t-[0.5px] dark:border-gray-700 mb-1"
//             />
//           );

//         return <ListItem key={i} title={item.label} href={item.href} />;
//       })}
//     </ul>
//   </NavigationMenu.Content>
// );

const MobileListItem = ({
  children,
  href,
  asPath,
  isBeta,
  onClick,
}: {
  asPath?: string;
  children: JSX.Element | string | JSX.Element[];
  href?: string;
  isBeta?: boolean;
  key?: any;
  onClick?: () => void;
}) => {
  return (
    <li className="flex">
      <Button
        className="w-auto flex items-center gap-[4px]"
        variant="ghost"
        size="sm"
        asPath={asPath}
        href={href}
        onClick={onClick}
      >
        {children}
        {isBeta && <BetaBadge />}
      </Button>
    </li>
  );
};

const MobileMenu = ({
  isActive,
  onClickMenuItem,
}: {
  isActive: boolean;
  onClickMenuItem: () => void;
}) => {
  const { show: onContactClick } = useContact();

  if (!isActive) return <></>;
  return (
    <AnimatePresence>
      <motion.nav
        aria-label="Mobile"
        className="w-full h-[100vh] top-12 left-0 absolute p-4 overflow-auto lg:hidden"
        initial={{ scale: 0, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        style={{ originX: 0.5, originY: 0 }}
        transition={{
          type: 'spring',
          stiffness: 230,
          damping: 20,
        }}
      >
        <ul className="bg-white dark:bg-cobalt-100 shadow-header-menu border-[0.5px] dark:border-gray-700 py-6 px-3 rounded-[12px]">
          <li className="mb-6">
            <div className="font-semibold text-xs text-gray-400 px-3 mb-1">
              Product
            </div>
            <ul className="grid grid-cols-2">
              {PRODUCT_LISTS.map((category, c) =>
                category.items.map((item, i) => (
                  <MobileListItem
                    key={`id-${c}-${i}`}
                    href={item.href}
                    isBeta={item.isBeta}
                    onClick={() => {
                      onClickMenuItem();
                    }}
                  >
                    {item.label}
                  </MobileListItem>
                )),
              )}
            </ul>
          </li>
          <li className="mb-6">
            <div className="font-semibold text-xs text-gray-400 px-3 mb-1">
              Resources
            </div>
            <ul className="grid grid-cols-2">
              {RESOURCES_LIST.map((item, i) => {
                if (item.type === 'separator') return;

                return (
                  <MobileListItem key={i} href={item.href}>
                    {item.label}
                  </MobileListItem>
                );
              })}
            </ul>
          </li>
          <li className="mb-2">
            <MobileListItem href={'/pricing'}>Pricing</MobileListItem>
          </li>
          <li>
            <MobileListItem onClick={onContactClick}>Contact</MobileListItem>
          </li>
        </ul>
      </motion.nav>
    </AnimatePresence>
  );
};

const MobileMenuButton = ({
  onClick,
  isActive = false,
}: {
  isActive?: boolean;
  onClick: () => void;
}) => (
  <Button
    size="sm"
    variant="ghost"
    className="border-[0.5px] dark:border-gray-700 lg:hidden"
    onClick={onClick}
  >
    <div className="relative w-[16px] h-[16px]">
      <div
        className={cn(
          'w-[18px] h-[1.5px] rounded-full absolute top-[2px] transition-transform transition-color',
          isActive ? 'rotate-45 translate-y-[5px] bg-gray-600' : 'bg-gray-400',
        )}
      />
      <div
        className={cn(
          !isActive
            ? 'w-[18px] h-[1.5px] bg-gray-400 rounded-full absolute top-[7px] transition-color'
            : 'bg-white',
        )}
      />
      <div
        className={cn(
          'w-[18px] h-[1.5px] rounded-full absolute mb-1 top-[12px] transition-transform transition-color',
          isActive
            ? '-rotate-45 -translate-y-[5px] bg-gray-600'
            : 'bg-gray-400',
        )}
      />
    </div>
    <span className="hidden">Menu</span>
  </Button>
);

const Header = () => {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const { isLoggedIn } = useContext(Context);
  const { show: onTalkToUsClick } = useContact();

  return (
    <>
      <Flash />

      <header className="sticky top-0 left-0 bg-white dark:bg-cobalt-100 shadow-header z-[200] items-center flex w-full h-[52px] justify-center font-inter">
        <div className="flex w-full justify-between items-center max-w-6xl px-4">
          <a
            href="/"
            className="transition-opacity hover:opacity-80 text-black dark:text-white"
          >
            <Logo />
          </a>

          <NavigationMenu.Root
            delayDuration={100}
            skipDelayDuration={200}
            className="relative z-[1] hidden lg:flex w-auto justify-center sm:min-w-[620px]"
          >
            <NavigationMenu.List className="center m-0 flex list-none p-1 gap-3">
              <MenuTopItem title="Product">
                <ProductMenu />
              </MenuTopItem>

              <MenuTopItem title="Resources">
                <ResourcesMenu />
              </MenuTopItem>

              <MenuTopItem title="Pricing" href="/pricing" />

              <MenuTopItem
                icon={`💬`}
                title="Contact us"
                onClickFn={onTalkToUsClick}
              />
            </NavigationMenu.List>
            <div className="perspective-[2000px] absolute top-full left-0 flex w-full justify-end">
              <NavigationMenu.Viewport className="data-[state=open]:animate-scaleIn data-[state=closed]:animate-scaleOut relative border border-[0.5px] dark:border-gray-700 h-[var(--radix-navigation-menu-viewport-height)] w-full origin-[top_center] overflow-hidden rounded-[8px] bg-white dark:bg-cobalt-100 shadow-header-menu transition-[width,_height] duration-300 sm:w-[var(--radix-navigation-menu-viewport-width)]" />
            </div>
          </NavigationMenu.Root>

          <MobileMenu
            onClickMenuItem={() => setMobileMenuActive(false)}
            isActive={mobileMenuActive}
          />

          <div className="flex gap-2">
            <Button
              href={isLoggedIn ? ROUTES.account : ROUTES.signIn}
              className="hidden lg:flex"
              size="sm"
              variant="ghost"
            >
              <span className="font-medium">
                {isLoggedIn ? 'Account' : 'Sign in'}
              </span>
            </Button>
            <HeaderCTA isLoggedIn={isLoggedIn} />
            <MobileMenuButton
              isActive={mobileMenuActive}
              onClick={() => setMobileMenuActive(!mobileMenuActive)}
            />
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
