import cn from 'classnames';
import React from 'react';

const ProductIcon = ({
  children,
  inverted = false,
  className,
}: {
  children: JSX.Element | string;
  className?: string;
  inverted?: boolean;
}) => {
  return (
    <div
      className={cn(
        'flex shrink-0 bg-rainbow h-[20px] w-[20px] items-center justify-center mr-[8px] rounded-[6px]',
        className,
      )}
    >
      <div
        className={cn(
          inverted
            ? 'bg-cobalt-50 text-white'
            : 'bg-white text-black dark:bg-cobalt-100 dark:text-white',
          'flex shrink-0 h-[17px] w-[17px] rounded-[4px] items-center justify-center',
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ProductIcon;
